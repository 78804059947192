import type { FC} from 'react';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import type { SelectChangeEvent } from '@mui/material';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { debounce } from 'ts-debounce';

import type {  RatesRolesType  } from '@/api/RatesService/rates.interface';
import ControlledAutocomplete from '@/components/Autocomplete';
import KabobMenu from '@/components/KabobMenu';
import type { EstimationFormFieldsType } from '@/pages/Estimation/Estimation.interface';
import { useClientContext } from '@/store/client';
import { useNotificationContext } from '@/store/notification';
import { useRateContext } from '@/store/rate';
import { Proficiency } from '@/utils/constants';
import { roundDecimal } from '@/utils/math/math-helper';

import type { DeliveryTeamFormProps } from './DeliveryTeamForm.interface';
import { useStyles } from './DeliveryTeamForm.styles';


const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
    border: 0,
    fontSize: 11,
    padding: '0 8px',
    '&:first-of-type': {
      paddingLeft: 0
    },
    '&:last-of-type': {
      paddingRight: 0
    }
  },
  [`&.${tableCellClasses.body}`]: {
    ...theme.typography.h5,
    border: 0,
    fontSize: 13,
    padding: '0 8px',
    '&:first-of-type': {
      paddingLeft: 0
    },
    '&:last-of-type': {
      paddingRight: 0
    }
  },
}));
const StyledTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInput-root': {
    width: '100%',
    marginTop: 3,
  },
  '& .MuiInput-input': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginLeft: 0,
  },
  '& .MuiInputAdornment-root': {
    marginRight: 0,
    marginLeft: 10,
    color: disabled ? theme.palette.text.disabled : theme.palette.text.secondary
  }
}));

export const DeliveryTeamForm: FC<DeliveryTeamFormProps> = ({fields, selectedClientProfile, onAdd, onRemove, onDuplicate, udpateSalesforceLabel, disabled}) => {
  const { classes } = useStyles();
  const { control, register, setValue, watch, formState: { errors } } = useFormContext<EstimationFormFieldsType>();
  const { dispatch: notification } = useNotificationContext();
  const { state: clients } = useClientContext();
  const { state: rates } = useRateContext();
  const clientOptions = clients.clientProfiles;

  const watchDeliveryTeamMembers = watch('deliveryTeamMembers');

  const checkRate = (value: number, slalomRate: number, id: string) => {
    const TEN_PERCENT = .1;
    const diff = roundDecimal(Math.abs(1 - (value / slalomRate)), 3);

    if(diff > TEN_PERCENT && value < slalomRate) {
      notification?.addNotification({
        id,
        severity: 'error',
        message: 'Rate override requires management approval.',
      });
    } else {
      notification?.removeNotification(id);
    }
  };

  const updateNumberInput = (value: number | string) => {
    let result = Math.max(Number(value), 0);
    if(isNaN(result)) result = 0;

    return result;
  };
  const watchClient = watch('client');

  const [clientRoleItems, setClientRoleItems] = React.useState<{label: string; id: number}[]>([]);
  const [slalomRolesArray, setSlalomRoles] = React.useState<{role: string; proficiencies: string[]}[]>([]);
  const [slalomProficiencies, setSlalomProficiencies] =  React.useState<string[][]>();
  const [allPractices, setAllPractices] = React.useState<string[]>([]);
  const [columnValue, setColumnValue] = React.useState('');
  const [filterRoleValue, setFilterRoleValue] = React.useState('');
  const [clientRoleOptions, setClientRoleOptions] = React.useState<JSX.Element[]>([]);

  const columuns = new Set<string>(['Client role','Slalom role','Proficiency','Capability','Salesforce label','Market rate','Rate','Hrs/Week']);
  const columnsOptions = Array.from(columuns)?.sort((a,b) => a.localeCompare(b)).map((item, index) => (<MenuItem key={index} value={item}>{item}</MenuItem>));

  // Get list of Slalom Roles and Practices for dropdowns
  useEffect(() => {
    const practices: string[] = [];
    let slalomRoleArray: { role: string; proficiencies: string[]; }[] = [];
    //iterate collection of rates. if a rate is 0, the proficiency should be omitted from the list of proficiencies. The goal is for each Slalom role to associate only appropriate proficieny levels with each respective role
    //  i.e. Solution Architect does not have a Foundational rate. Therefore, this role will only have Proficient, Advanced, and Expert proficiency levels associated with it.
    if (rates.marketRate && slalomRolesArray.length === 0) {
      slalomRoleArray = createSlalomRolesProficiencies(rates.marketRate?.rates);
      slalomRoleArray.sort((a,b) => a.role.localeCompare(b.role));
      setSlalomRoles(slalomRoleArray);

      rates.marketRate?.capabilities.forEach((item) => {
        item.practice.forEach((practice) => {
          if (!practices.includes(practice)) {
            practices.push(practice);
          }
        });
      });
      practices.sort((a, b) => a.localeCompare(b));
      setAllPractices(practices);
    }
  }, [rates.marketRate]);

  // Update Client Role dropdown when Client Name selected
  useEffect(() => {
    if (watchClient === '') {
      setClientRoleItems([]);
    } else {
      const client = clientOptions?.find((client) => client.clientName === watchClient);
      const clientRoles:{label: string; id: number}[] = [];
      if (client) {
        if (client.roles.length) {
          let num = 0;
          client.roles.forEach((item) => {
            clientRoles.push({id: num++, label: item.clientRole});
            clientRoles.sort((a, b) => a.label.localeCompare(b.label));
          });
        }
      }
      setClientRoleItems(clientRoles);
    }

  }, [watchClient]);

  useEffect(()=>{
    const tempClientRoles:{label: string; id: number}[] = [];
    let clientRoles:Set<string> = new Set<string>();
    clientRoles = new Set<string>(watchDeliveryTeamMembers?.map((item )=>{
      if(item.clientRole)
        return item.clientRole;
    }) as string[]);
    let num = 0;
    clientRoles?.forEach((item) => {
      if (item) {
        tempClientRoles.push({id: num++, label: item});
        tempClientRoles?.sort((a, b) => a.label.localeCompare(b.label));
      }
    });
    setClientRoleOptions(Array.from(tempClientRoles)?.sort((a,b) => a.label.localeCompare(b.label)).map((item, index) => (<MenuItem key={index} value={item.label}>{item.label}</MenuItem>)));
    //using array vs set b/c a team can have duplicate slalom roles, just with different proficiency
    let watchDeliveryTeamMembersSlalomRoles: string[] = [];
    watchDeliveryTeamMembersSlalomRoles = watchDeliveryTeamMembers?.map(item => item.slalomRole) as string[];
    const filteredRoles = watchDeliveryTeamMembersSlalomRoles?.map(
      role => slalomRolesArray.find(item => item?.role === role)
    ).filter(Boolean);
    const proficiencies: string[][] = [];
    if (filteredRoles != undefined) {
      for (const slalomRole of filteredRoles) {
        if(slalomRole != undefined) {
          proficiencies.push(slalomRole.proficiencies);
        }
      }
    }
    setSlalomProficiencies(proficiencies);
  },[onAdd || onRemove]);

  useEffect(()=>{
    setColumnValue('');
    setFilterRoleValue('');
  },[ watchDeliveryTeamMembers]);

  const handleColumnChange = (event: SelectChangeEvent) => {
    setColumnValue(event.target.value);
  };
  const handleSelectedRoleChange = (event: SelectChangeEvent) =>{
    setFilterRoleValue(event.target.value);
  };

  const handleRoleOrProficiencyChange = (id: string): number | '' => {
    const teamMember = watchDeliveryTeamMembers.filter(x => x.id === id)[0];
    return teamMember.slalomRate;
  };

  const isNegotiatedFixedRate = (selectedRoleName: string) => {
    const mappedClientProfileRole = selectedClientProfile?.roles.find(role => role.clientRole === selectedRoleName);
    return mappedClientProfileRole ? mappedClientProfileRole.fixedRate : false;
  };

  return (
    <div data-testid="DeliveryTeamForm">
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1} justifyContent='space-between'>
          <Typography variant='h3' color='text.secondary'>Delivery Team</Typography>
          {
            <Stack direction='row' spacing={4} justifyContent='flex-end' sx={{ paddingBottom : 2 }}>
              <FormControl variant="standard" size="small">
                <Select
                  className={classes.dropDown}
                  labelId="filter-by-role-label"
                  id="filter-by-role"
                  data-testid="filter-by-role"
                  value={filterRoleValue}
                  onChange={handleSelectedRoleChange}
                  displayEmpty={true}
                >
                  <MenuItem value=''>Filter by role</MenuItem>
                  {clientRoleOptions}
                </Select>
              </FormControl>
              <FormControl variant="standard" size="small">
                <Select
                  className={classes.dropDown}
                  labelId="sort-by-label"
                  id="sort-by"
                  data-testid="sort-by"
                  value={columnValue}
                  onChange={handleColumnChange}
                  displayEmpty={true}
                >
                  <MenuItem value=''>Sort by</MenuItem>
                  {columnsOptions}
                </Select>
              </FormControl>
            </Stack>
          }

        </Stack>
        <TableContainer>
          <Table sx={{ minWidth: 650, maxWidth:'85%' }} aria-label="delivery team table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Client role</StyledTableCell>
                <StyledTableCell>Slalom role*</StyledTableCell>
                <StyledTableCell>Proficiency*</StyledTableCell>
                <StyledTableCell>Capability*</StyledTableCell>
                <StyledTableCell>Salesforce label* <Tooltip sx={{padding: 0}} title={'A text descriptive name for the role that the user can choose to use when the project estimate is entered into Salesforce.'}><IconButton><InfoIcon color='primary' style={{ fontSize: 15 }}/></IconButton ></Tooltip></StyledTableCell>
                <StyledTableCell className={classes.condenseCell}>Market rate <Tooltip sx={{padding: 0}} title={'Displayed rates are based on your market.'}><IconButton><InfoIcon color='primary' style={{ fontSize: 15 }}/></IconButton ></Tooltip></StyledTableCell>
                <StyledTableCell className={classes.condenseCell}>Rate* <Tooltip sx={{padding: 0}} title={'Actual rate used to generate cost estimate. This overrides market rate.'}><IconButton><InfoIcon color='primary' style={{ fontSize: 15 }}/></IconButton ></Tooltip></StyledTableCell>
                <StyledTableCell className={classes.condenseCell}>Hrs/Week*</StyledTableCell>
                <StyledTableCell className={classes.condenseCell} align='center'>Contingency</StyledTableCell>
                <StyledTableCell className={classes.condenseCell} align='center'/>
              </TableRow>
            </TableHead>
            <TableBody>
              {watchDeliveryTeamMembers
                ?.sort((a, b) => {
                  switch (columnValue) {
                  case 'Client role':
                    return a.clientRole.localeCompare(b.clientRole);
                  case 'Slalom role':
                    return a.slalomRole.localeCompare(b.slalomRole);
                  case 'Proficiency':
                    return a.proficiency.localeCompare(b.proficiency);
                  case 'Capability':
                    return a.practice.localeCompare(b.practice);
                  case 'Salesforce label':
                    return a.salesforceLabel.localeCompare(b.salesforceLabel);
                  case 'Market rate':
                    return Number(a.slalomRate) - Number(b.slalomRate);
                  case 'Rate':
                    return Number(a.clientRate) - Number(b.clientRate);
                  case 'Hrs/Week':
                    return Number(a.defaultHoursPerWeek) - Number(b.defaultHoursPerWeek);
                  default:
                    return 1;
                  }
                }).map((field, index) =>  {
                  return (!filterRoleValue || filterRoleValue == field.clientRole) && (
                    <TableRow key={field.id}>
                      <StyledTableCell className={classes.stretchCell}>
                        <Controller
                          name={`deliveryTeamMembers.${index}.clientRole`}
                          control={control}
                          render={({ field }) => (
                            <ControlledAutocomplete
                              options={clientRoleItems}
                              renderProps={field}
                              disabled = {disabled}
                            />
                          )}/>
                      </StyledTableCell>
                      <StyledTableCell className={classes.stretchCell}>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            {...register(`deliveryTeamMembers.${index}.slalomRole`)}
                            value={watchDeliveryTeamMembers[index].slalomRole}
                            displayEmpty
                            disabled = {disabled}
                            error={!!errors['deliveryTeamMembers']?.[index]?.slalomRole}
                            onChange={async () => {
                              const id = watchDeliveryTeamMembers[index].id;
                              const marketRate = handleRoleOrProficiencyChange(id);
                              await setValue(`deliveryTeamMembers.${index}.clientRate`, marketRate, {shouldDirty: true, shouldValidate:true});
                            }}
                          >
                            <MenuItem value=''>Select option</MenuItem>
                            {slalomRolesArray.map((item, i) => {return <MenuItem key={i} value={item.role}>{item.role}</MenuItem>;})}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell className={classes.stretchCell}>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            {...register(`deliveryTeamMembers.${index}.proficiency`)}
                            value={watchDeliveryTeamMembers[index].proficiency}
                            displayEmpty
                            disabled = {disabled}
                            error={!!errors['deliveryTeamMembers']?.[index]?.proficiency}
                            onChange={async (e) => {
                              await setValue(`deliveryTeamMembers.${index}.proficiency`, e.target.value, {shouldDirty: true, shouldValidate: true});
                              const id = watchDeliveryTeamMembers[index].id;
                              const marketRate = handleRoleOrProficiencyChange(id);
                              await setValue(`deliveryTeamMembers.${index}.clientRate`, marketRate, {shouldDirty: true, shouldValidate:true});
                            }}
                          >
                            <MenuItem value=''>Select option</MenuItem>
                            {slalomProficiencies?.[index]?.map((item, i) => <MenuItem key={i} value={item}>{item}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell className={classes.normalCell}>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            {...register(`deliveryTeamMembers.${index}.practice`)}
                            value={watchDeliveryTeamMembers[index].practice}
                            displayEmpty
                            disabled = {disabled}
                            error={!!errors['deliveryTeamMembers']?.[index]?.practice}
                          >
                            <MenuItem value=''>Select option</MenuItem>
                            {allPractices.map((item, i) => (<MenuItem key={i} value={item}>{item}</MenuItem>))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell className={classes.stretchCell}>
                        <TextField
                          {...register(`deliveryTeamMembers.${index}.salesforceLabel`)}
                          fullWidth
                          placeholder='Enter label'
                          inputProps={{style: {fontSize: 13}}}
                          onChange={debounce(async (e) => {
                            await setValue(`deliveryTeamMembers.${index}.salesforceLabel`, e.target.value, {shouldDirty: true, shouldValidate: true});
                            udpateSalesforceLabel();
                          }, 1000)}
                          disabled = {disabled}
                          error={!!errors['deliveryTeamMembers']?.[index]?.salesforceLabel}
                        />
                      </StyledTableCell>
                      <StyledTableCell className={classes.condenseCell} >
                        <TextField
                          className={classes.textField}
                          fullWidth
                          value={watchDeliveryTeamMembers[index].slalomRole && watchDeliveryTeamMembers[index].proficiency ? watchDeliveryTeamMembers[index].slalomRate.toLocaleString('en-US') : 0 }
                          InputProps={{
                            style: {fontSize: 13},
                            startAdornment:
                          <InputAdornment position="start">
                            <Typography color='lightgrey'>$</Typography>
                          </InputAdornment>
                          }}
                          disabled
                        />
                      </StyledTableCell>
                      <StyledTableCell className={classes.condenseCell}>
                        <StyledTextField
                          {...register(`deliveryTeamMembers.${index}.clientRate`)}
                          className={classes.textField}
                          fullWidth
                          disabled={disabled || isNegotiatedFixedRate(watchDeliveryTeamMembers[index].clientRole)}
                          error={!!errors['deliveryTeamMembers']?.[index]?.clientRate}
                          InputProps={{
                            style: {fontSize: 13},
                            disabled: disabled || isNegotiatedFixedRate(watchDeliveryTeamMembers[index].clientRole),
                            startAdornment:
                            <InputAdornment position="start" disableTypography={disabled}>
                              <Typography color={isNegotiatedFixedRate(watchDeliveryTeamMembers[index].clientRole) || disabled ? 'lightgrey': 'inherit'}>$</Typography>
                            </InputAdornment>
                          }}
                          inputProps={{
                            'data-testid': `deliveryTeamMembers.${index}.clientRate`
                          }}
                          onChange={debounce(async (e) => {
                            const value = updateNumberInput(e.target.value);
                            const slalomRate = Number(watchDeliveryTeamMembers[index].slalomRate);

                            checkRate(value, slalomRate, `${watchDeliveryTeamMembers[index].id}-client-rate`);
                            await setValue(`deliveryTeamMembers.${index}.clientRate`, value, {shouldDirty: true, shouldValidate: true});
                          }, 1000)}
                        />
                      </StyledTableCell>
                      <StyledTableCell className={classes.condenseCell}>
                        <TextField
                          {...register(`deliveryTeamMembers.${index}.defaultHoursPerWeek`)}
                          className={classes.textField}
                          fullWidth
                          inputProps={{style: {fontSize: 13}}}
                          onChange={debounce(async (e) => {
                            const value = updateNumberInput(e.target.value);

                            await setValue(`deliveryTeamMembers.${index}.defaultHoursPerWeek`, value, {shouldDirty: true});
                          }, 1000)}
                          disabled = {disabled}
                          error={!!errors['deliveryTeamMembers']?.[index]?.defaultHoursPerWeek}
                        />
                      </StyledTableCell>
                      <StyledTableCell align='center' className={classes.condenseCell}>
                        <Switch
                          checked={watchDeliveryTeamMembers[index].contingency}
                          onChange={(e) => {
                            setValue(`deliveryTeamMembers.${index}.contingency`, e.target.checked, {shouldDirty: true});
                          }}
                          disabled = {disabled}
                        />
                      </StyledTableCell>
                      <StyledTableCell align='center' className={classes.condenseCell}>
                        <KabobMenu id={`duplicate-delete-menu-${index}`} items={[
                          {
                            option: 'Duplicate',
                            value: 'Duplicate',
                            onClick: () => {
                              onDuplicate(index);
                            },
                          },
                          {
                            option: 'Delete',
                            value: 'Delete',
                            onClick: () => {
                              if (fields.length > 1) {
                                onRemove(index);
                              }
                            },
                          }
                        ]}
                        disabled = {disabled}
                        color = {disabled ?'disabled':'primary'}
                        />
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Button
        data-testid='add-delivery-team-member'
        className={classes.addButton}
        startIcon={<AddIcon />}
        variant='outlined'
        size='small'
        onClick={onAdd}
        disabled = {disabled}
      >
        Add Role
      </Button>
    </div>
  );
};

function createSlalomRolesProficiencies(rates: RatesRolesType[]): { role: string; proficiencies: string[]; }[] {
  const slalomRoleArray: { role: string; proficiencies: string[]; }[] = [];

  if (!rates || !rates.length) return slalomRoleArray;
  rates.forEach((rate) => {
    const proficiencyArr: string[] = [];
    if (rate?.expertRate > 0) {
      proficiencyArr.push(Proficiency.EXPERT);
    }
    if (rate?.advancedRate > 0) {
      proficiencyArr.push(Proficiency.ADVANCED);
    }
    if (rate?.proficientRate > 0) {
      proficiencyArr.push(Proficiency.PROFICIENT);
    }
    if (rate?.foundationalRate > 0) {
      proficiencyArr.push(Proficiency.FOUNDATIONAL);
    }
    slalomRoleArray.push({
      role: rate?.role || '', // Handle potential missing role
      proficiencies: proficiencyArr,
    });
  });
  return slalomRoleArray;
}
export { createSlalomRolesProficiencies };
